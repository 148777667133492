body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
}

.filter-buttons {
  margin: 0 0 1em 0;
}

.filter-button {
  cursor: pointer;
  border-radius: 25px;
  margin: 0 0.25em;
  padding: 0.45em 1.75em;
  background-color: #a6a6a6;
}

.filter-button:hover {
  filter: brightness(70%);
}

.room-map-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

table, th, td[style] {
  border: 2px solid;
  border-spacing: 0 16px;
}

table {
  border-collapse: separate;
  table-layout: fixed;
  width: 16em;
  height: 20em;
}

.nodes {
  position: absolute;
  border: 1px solid;
  top: 90px;
  width: 100px;
  height: 100px;
  font-weight: bold;
}

.node-1 {
  position: absolute;
  border: 1px solid;
  top: 15px;
  left: 89px;
  padding: 0.33em 0;
}

.node-2 {
  position: absolute;
  border: 1px solid;
  top: 55px;
  left: 89px;
  padding: 0.33em 0;
}

.node-3 {
  position: absolute;
  border: 1px solid;
  top: 25px;
  left: 55px;
  padding: 0.33em 0;
  transform: rotate(-20deg);
}

.node-4 {
  position: absolute;
  border: 1px solid;
  top: 60px;
  left: 35px;
  padding: 0 0.95em;
  height: 10px;
}

.node-4 span {
  position: absolute;
  left: 10px;
  top: -6px;
}

.node-5 {
  position: absolute;
  border: 1px solid;
  top: 25px;
  left: 35px;
  padding: 0.33em 0;
  transform: rotate(20deg);
}

.node-6 {
  position: absolute;
  border: 1px solid;
  top: 15px;
  left: 1px;
  padding: 0.33em 0;
}

.node-7 {
  position: absolute;
  border: 1px solid;
  top: 55px;
  left: 1px;
  padding: 0.33em 0;
}

@media only screen and (min-width: 360px) {
  table {
    width: 20em;
    height: 25em;
  }

  .nodes {
    font-size: 1.5em;
    width: 150px;
    height: 150px;
  }

  .node-1 {
    top: 25px;
    left: 133px;
  }

  .node-2 {
    top: 85px;
    left: 133px;
  }

  .node-3 {
    top: 35px;
    left: 90px;
    transform: rotate(-30deg);
  }

  .node-4 {
    top: 85px;
    left: 50px;
    padding: 0.31em 0.95em;
    height: 0;
  }

  .node-4 span {
    position: absolute;
    left: 15px;
    top: -10px;
  }

  .node-5 {
    top: 35px;
    left: 45px;
    transform: rotate(30deg);
  }

  .node-6 {
    top: 25px;
    left: 2px;
  }

  .node-7 {
    top: 85px;
    left: 2px;
  }
}

.room-map {
  width: 300px;
}

.big-room-entrance {
  width: 100px;
  height: 2px;
  transform: translate(0px, -2px);
  background-color: white;
}

.small-room-entrance {
  width: 75px;
  height: 2px;
  transform: translate(37.5px, 150px);
  background-color: white;
}

.solution {
  position: absolute;
  top: 250px;
  font-size: 2em;
  font-weight: bold;
}

.route-map {
  max-width: 480px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.terminal-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 1.17em;
}

.terminal-individual-input, .highlight, .no-highlight {
  display: inline;
}

.highlight {
  font-weight: bold;
  text-decoration: underline;
}

h1, h2, h3 {
  text-align: center;
}

h1, h2, h3 {
  margin: 0;
}

ul.history {
  list-style: none;
  margin: 0 0 1em 0;
  padding: 0;
}

.clear-inputs {
  margin: 0.5em 0;
}

/* Code for positioning buttons comes from Pastuleo23 (AKA Cheese Forever on YouTube) */
/* Cheese Forever's channel: https://www.youtube.com/channel/UCl1M49CAo9u99gABBIqEgDQ */
.terminal-buttons {
  position: relative;
  /* top: 573px; */
  top: 61%;
  height: 250px;
  width: 250px;
}

.terminal-buttons button:not(.progress button) {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: 1.25em;
  font-weight: bold;
}

@media only screen and (min-width: 800px) {
  .terminal-buttons button:not(.progress button):hover:enabled {
    color: #938200;
  }  
}

.progress {
  position: absolute;
  top: 34%;
  left: 28%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.progress * { 
  margin: 0 0 0.75em 0;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 25em;
  background-color: black;
  height: 1px;
  opacity: 0.5;
}

.wheel {
  width: 240px;
  height: 240px;
  padding-top: 5px;
  padding-left: 10px;
}

.button-1 {
  position: absolute;
  top: 0px;
  left: 128px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-2 {
  position: absolute;
  top: 27px;
  left: 176px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-3 {
  position: absolute;
  top: 73px;
  left: 204px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-4 {
  position: absolute;
  top: 125px;
  left: 204px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-5 {
  position: absolute;
  top: 173px;
  left: 180px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-6 {
  position: absolute;
  top: 199px;
  left: 136px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-7 {
  position: absolute;
  top: 202px;
  left: 84px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-8 {
  position: absolute;
  top: 178px;
  left: 34px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-9 {
  position: absolute;
  top: 132px;
  left: 4px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-10 {
  position: absolute;
  top: 78px;
  left: 1px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-11 {
  position: absolute;
  top: 29px;
  left: 27px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.button-12 {
  position: absolute;
  top: 1px;
  left: 74px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.credit {
  text-align: center;
}

.alert {
  text-align: left;
  color: red;
}

.alert h4 {
  margin: 0;
}

.how-to-guide, .time-glitch {
  margin: 0 1em 1em 1em;
  max-width: 320px;
  text-align: left;
}

iframe {
  margin: 1em 0 1em 0;
  max-width: 320px;
}

.time-glitch p {
  margin: 0;
}

.how-to-guide img {
  display: block;
  margin: 1em auto;
  max-width: 320px;
}

img {
  display: block;
  margin: 1em auto;
  max-width: 480px;
}

.route-map {
  max-width: 240px;
}

p .button-instructions {
  display: inline;
}

@media only screen and (min-width: 480px) {
  .filter-button {
    margin: 0 0.5em;
  }

  .how-to-guide, .time-glitch {
    max-width: 480px;
  }
  
  iframe {
    max-width: 480px;
  }
  
  .how-to-guide img {
    max-width: 480px;
  }  

  .route-map {
    max-width: 320px;
  }
}

span[style] {
  font-weight: bold;
}